import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import BasicL10nPageTemplate from '../components/BasicL10nPageTemplate'
import { HTMLContent } from '../components/ContentLegacy'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';

const BasicPage = ({ data, location}) => {
  const { markdownRemark: post } = data


  const url = location.pathname; 
  console.log(url)
  const {langs, defaultLangKey} = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);

  const homeLink = `/${langKey}/`;
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));

  return (
    <Layout>
      <BasicL10nPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        lang={langsMenu}
        url={url}
      />
    </Layout>
  )
}

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicPage

export const aboutPageQuery = graphql`
  query BasicL10nPage($id: String!) {
    site {
      siteMetadata{
        languages {
          defaultLangKey
          langs
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
